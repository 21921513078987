import React, {useEffect} from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import SEO from "./seo"
import 'focus-within-polyfill'

class Header extends React.Component {

  constructor() {
    super()
    this.state = { animate: true }
  }

  componentDidMount () {
    if(window.sessionStorage.getItem("firstLoadDone") === null) {
      this.setState({animate: true,})
      window.sessionStorage.setItem("firstLoadDone", 1)
    } else {
      this.setState({animate: false,})
    }
  }

  render() {
    let props = this.props
    let headerBlurb
    // let header = (
    //   <header className={props.fullwidth != null && props.fullwidth == "true" ? "top top--fullwidth" : "top" }>
    //     <Image fluid={props.data.bannauLogo.childImageSharp.fluid} style={{width: '50px', margin: '0 5px 0 0'}} />
    //     <Link to='/' style={{textDecoration: 'none'}}><h2 style={{margin: '0'}}>Bannau</h2></Link>
    //     <nav className="top">
    //       <ul>
    //         <Link to='/angebot/altersheim'><li className='nav-item'>Über Uns</li></Link>
    //         <a href="#"><li className='nav-item'>Link 2</li></a>
    //         <a href="#"><li className='nav-item'>Link 3</li></a>
    //       </ul>
    //     </nav>
    //   </header>
    // )
    
    let header = (
      <header className={props.fullwidth != null && props.fullwidth === "true" ? "top top--fullwidth" : "top" }>
      <Link to='/' style={{display: 'flex', textDecoration: 'none', padding: '0', backgroundColor: 'transparent'}}>
        <Image fluid={props.data.bannauLogo.childImageSharp.fluid} className="logo-header" />
        <h2 style={{margin: '0'}} className="bannau-logo-text">Bannau</h2>
      </Link>
      <div className="nav">
      <nav>
          <button className="mobile-menu-trigger">Menü</button>
          <ul className="menu menu-bar">
            <li tabIndex="0">
              <button className="menu-link menu-bar-link h-haspopup" aria-haspopup="true">Über uns</button>
              <ul className="mega-menu mega-menu--multiLevel">
                <li>
                  <Link to="/geschichte-traegerschaft/" className="menu-link mega-menu-link" aria-haspopup="true">Geschichte & Trägerschaft</Link>
                  <ul className="menu-content">
                    <h5>Geschichte & Trägerschaft</h5>
                    <p style={{marginBottom: '10px'}}>Das Altersheim Bannau existiert bereits seit dem 19-ten Jahrhundert, geprägt durch eine lange Geschichte.</p>
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <Image
                        fluid={props.data.imgGeschichteFrueher.childImageSharp.fluid}
                        style={{width: '35%', margin: '0 5px 0 0'}}
                      />
                      <Image
                        fluid={props.data.imgGeschichteUmbau.childImageSharp.fluid}
                        style={{width: '35%', margin: '0 0 0 5px'}}
                      />
                    </div>
                  </ul>
                </li>
                <li>
                  <Link to="/leitbild-organigramm/" className="menu-link mega-menu-link">Leitbild & Organigramm</Link>
                </li>
                <li>
                  <a href="/leitung/" className="menu-link mega-menu-link">Leitung</a>
                  {/* <ul className="menu menu-list">
                      <li>
                          <a href="/page" className="menu-link menu-list-link">1.2.1 Page link</a>
                      </li>
                      <li>
                          <a href="/page" className="menu-link menu-list-link">1.2.2 Page link</a>
                      </li>
                  </ul> */}
                </li>
                <li>
                  <a href="/team/" className="menu-link mega-menu-link">Team</a>
                  {/* <ul className="menu menu-list">
                      <li>
                          <a href="/page" className="menu-link menu-list-link">1.3.1 Page link</a>
                      </li>
                      <li>
                          <a href="/page" className="menu-link menu-list-link">1.3.2 Page link</a>
                      </li>
                  </ul> */}
                </li>
                <li>
                  <Link to="/freiwilligen-arbeit/" className="menu-link mega-menu-link">Freiwilligen-Arbeit</Link>
                </li>
                <li>
                  <Link to="/jobs/" className="menu-link mega-menu-link">Offene Stellen</Link>
                </li>
                <li className="mobile-menu-back-item">
                  <button className="menu-link mobile-menu-back-link">Zurück</button>
                </li>
              </ul>
            </li>

            <li tabIndex="1">
              <button className="menu-link menu-bar-link" aria-haspopup="true">Wohnen</button>
              <ul className="mega-menu mega-menu--multiLevel">
                <li>
                  <Link to="/angebot/altersheim/" className="menu-link mega-menu-link" aria-haspopup="true">Altersheim</Link>
                  <ul className="menu-content">
                    <h5>Altersheim</h5>
                    <p style={{marginBottom: '10px'}}>Wir bieten unseren Bewohnenden 27 Einerzimmer und 1 Wohneinheit für Paare in ruhiger und zentrumsnaher Lage.</p>
                  </ul>
                </li>
                <li>
                  <Link to="/angebot/kurzaufenthalt/" className="menu-link mega-menu-link" aria-haspopup="true">Kurzaufenthalt</Link>
                  <ul className="menu-content">
                    <h5>Kurzaufenthalt</h5>
                    <p style={{marginBottom: '10px'}}>Möchten Sie das Leben im Altersheim Bannau kennenlernen oder bei uns Probewohnen? </p>
                  </ul>
                </li>
                <li className="mobile-menu-back-item">
                    <button className="menu-link mobile-menu-back-link">Zurück</button>
                </li>
              </ul>
            </li>

            <li tabIndex="2">
                <button className="menu-link menu-bar-link" aria-haspopup="true">Betreuung & Aktivitäten</button>
                <ul className="mega-menu mega-menu--multiLevel">
                    <li>
                        <Link to="/angebot/alltagsgestaltung-aktivierung/" className="menu-link mega-menu-link" aria-haspopup="true">Alltagsgestaltung Aktivierung</Link>
                        <ul className="menu-content" style={{padding: '0'}}>
                          <div  style={{height: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
                            <div style={{padding: '20px'}}>
                              <h5>Alltagsgestaltung Aktivierung</h5>
                              <p style={{marginBottom: '10px'}}>Aktivierung ist eine ganzheitliche Begleitung, die den Alltag unterstützt, erleichtert und bereichert. Dazu gehört die Förderung Beweglichkeit der beim Turnen, Ausflüge und mehr.</p>
                            </div>
                            <div style={{flexBasis: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                              <Image
                                fluid={props.data.imgAlltag.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                              <Image
                                fluid={props.data.imgAlltagBall.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                            </div>
                          </div>

                        </ul>
                    </li>
                    <li>
                        <Link to="/angebot/gedaechtnistraining/" className="menu-link mega-menu-link" aria-haspopup="true">Gedächtnistraining</Link>
                        <ul className="menu-content" style={{padding: '0'}}>
                          <div style={{height: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
                            <div style={{padding: '20px'}}>
                              <h5>Gedächtnistraining</h5>
                              <p style={{marginBottom: '10px'}}>Gedächtnistraining ist ein Sammelbegriff für Betreuungsmassnahmen mit dem Ziel, auf spielerische Weise kognitive Fähigkeiten zu erhalten und zu fördern.</p>
                            </div>
                            <div style={{width: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                              <Image
                                fluid={this.props.data?.imgGedTraining?.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                              <Image
                                fluid={this.props.data?.imgGedTraining2?.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                            </div>
                          </div>

                        </ul>
                    </li>
                    <li>
                        <Link to="/angebot/shiatsu/" className="menu-link mega-menu-link" aria-haspopup="true">Shiatsu</Link>
                        <ul className="menu-content" style={{padding: '0'}}>
                          <div style={{height: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
                            <div style={{padding: '20px'}}>
                              <h5>Shiatsu Programm</h5>
                              <p style={{marginBottom: '10px'}}>Shiatsu ist eine in Japan entwickelte Form der Körpertherapie die aus der traditionellen chinesischen Massage TUINA hervorgegangen ist. In Europa wird Shiatsu vielfach als Entspannungsmethode angewandt.</p>
                            </div>
                            <div style={{width: '600px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                              <Image
                                fluid={this.props.data?.shiatsu1?.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                              <Image
                                fluid={this.props.data?.shiatsu2?.childImageSharp.fluid}
                                style={{flexGrow: '1', width: '100%', margin: '0'}}
                              />
                            </div>
                          </div>

                        </ul>
                    </li>
                    <li>
                      <Link to="/heimgottesdienst/" className="menu-link mega-menu-link">Heimgottesdienst</Link>
                    </li>
                    <li className="mobile-menu-back-item">
                        <button className="menu-link mobile-menu-back-link">Zurück</button>
                    </li>
                </ul>
            </li>

            <li>
              <Link to="/en-guete/" className="menu-link menu-bar-link">En Guete</Link>
            </li>

            <li tabindex="3">
              <button className="menu-link menu-bar-link" aria-haspopup="true">Aktuelles</button>
              <ul className="mega-menu mega-menu--multiLevel">
                <li>
                  <Link to="/termine/" className="menu-link mega-menu-link" aria-haspopup="true"><span style={{marginRight: '0.5em'}} role="img" aria-label="termine">🗓️</span> Termine</Link>
                  <ul className="menu-content">
                    <h5>Termine</h5>
                    <p style={{marginBottom: '10px'}}>Hier finden Sie die Auflistung der bevorstehenden Veranstaltungen.</p>
                  </ul>
                </li>
                <li>
                  <Link to="/tagebuch/" className="menu-link mega-menu-link" aria-haspopup="true"><span style={{marginRight: '0.5em'}} role="img" aria-label="tagebuch">📖</span> Tagebuch (Blog)</Link>
                  <ul className="menu-content">
                    <h5>Tagebuch Altersheim Bannau</h5>
                    <p style={{marginBottom: '10px'}}>Wir berichten von unseren Ausflügen, Feieren und Veranstaltungen. Stöbern Sie gerne in unserem Tagebuch-Blog!</p>
                  </ul>
                </li>
                <li className="mobile-menu-back-item">
                    <button className="menu-link mobile-menu-back-link">Zurück</button>
                </li>
              </ul>
            </li>

            {/* <li>
                <a href="javascript:void(0);" className="menu-link menu-bar-link" aria-haspopup="true">2. Wohnen</a>
                <ul className="mega-menu mega-menu--flat">
                    <li>
                        <a href="/page" className="menu-link mega-menu-link mega-menu-header">2.1 Page link header</a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.1.1 Page link</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.1.2 Page link</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.1.3 Page link</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/page" className="menu-link mega-menu-link mega-menu-header">2.2 Page link header</a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.2.1 Page link</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.2.2 Page link</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.2.3 Page link</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="/page" className="menu-link mega-menu-link mega-menu-header">2.3 Page link header</a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.2.1 Page link</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">2.2.2 Page link</a>
                            </li>
                        </ul>
                    </li>
                    <li className="mobile-menu-back-item">
                        <a href="javascript:void(0);" className="menu-link mobile-menu-back-link">Zurück</a>
                    </li>
                </ul>
            </li> */}

              <li tabIndex="4">
                  <button className="menu-link menu-bar-link" aria-haspopup="true">Kontakt</button>
                  <ul className="mega-menu mega-menu--flat">
                      <li>
                          {/* <a href="#" className="menu-link mega-menu-link mega-menu-header">3.1 Page link header</a> */}
                          <ul className="menu menu-list">
                              <li>
                                  <a href="/anmeldung/" className="menu-link menu-list-link">
                                      Anmeldung<br />
                                      <small>Online Anmeldeformular</small>
                                  </a>
                              </li>
                              <li>
                                  <a href="/formulare-dokumente/" className="menu-link menu-list-link">
                                      Wichtige Formulare & Dokumente<br />
                                      <small>Leitbild, Taxordnung, BESA & mehr</small>
                                  </a>
                              </li>
                              <li>
                                  <a href="/jobs/" className="menu-link menu-list-link">
                                      Offene Stellen<br />
                                      <small>Stellenausschreibungen und Lehrbetrieb</small>
                                  </a>
                              </li>
                          </ul>
                      </li>
                      <li className="mega-menu-content">
                          <p className="mega-menu-header" style={{marginTop: '20px'}}>Kontaktieren Sie uns</p>
                          <p>
                            Altersheim Bannau<br/>
                            Gaswerkstrasse 6<br/>
                            8570 Weinfelden<br/><br/>

                            <a href="mailto:info@bannau.ch"><span style={{marginRight: '0.5em'}} role="img" aria-label="e-mail">✉️</span> info@bannau.ch</a>
                            <a href="tel:+41716221411"><span style={{marginRight: '0.5em'}} role="img" aria-label="telefon">📞</span> +41 71 622 14 11</a>
                          </p>
                      </li>
                      <li className="mobile-menu-back-item">
                          <button className="menu-link mobile-menu-back-link">Zurück</button>
                      </li>
                  </ul>
              </li>
            
              {/* <li>
                  <a href="/page" className="menu-link menu-bar-link">Static link</a>
              </li> */}

              <li className="mobile-menu-header">
                  <Link to="/" className="mobile-menu-header--top">
                      <span>Startseite</span>
                  </Link>
              </li>
          </ul>
      </nav>
      </div>
    </header>
    )



    

    /* Header (Nav) */
    if(props.fullwidth != null && props.fullwidth == "true") {
      header = (
        <div className="container-fullwidth">
          {header}
        </div>
      )
    }

    /* Header Blurb */
    if (props.headerImage != null) {
      headerBlurb = (
        <div className="header-container">
          <Image 
            fluid={props.headerImage}
            className="header-container__image"
          />
          <div className={this.state.animate ? "layer-drop-down-animation header-container__content" : "header-container__content"}>
            <h1 className="header-container__title">{props.title}</h1>
            <h3 className="header-container__slogan">{props.slogan}</h3>
          </div>
        </div>
      )
    }
    // } else if (props.fullwidth == null) {
    //   headerBlurb = (
    //     <Link className="header-link-home" to="/">
    //       {props.title}
    //     </Link>
    //   )
    // }

    /*
    return (
      <>
      <header className={props.hideBorder != null && props.hideBorder == "true" ? "top" : "top top--border" }>
        <Link to='/' style={{textDecoration: 'none'}}><h2 style={{margin: '0'}}>Bannau</h2></Link>
        <nav className="top">
          <ul>
            <Link to='/ueber-uns'><li className='nav-item'>Über Uns</li></Link>
            <Link to='/angebot'><li className='nav-item'>Angebot</li></Link>
            <Link to='/kontakt'><li className='nav-item'>Angebot</li></Link>
          </ul>
        </nav>
      </header>
      {headerBlurb}
      </>
    )*/


    return (
      <>
      <SEO title={props.title} />
      {header}
      {headerBlurb}
      </>
    )
  }
}

export default Header
