import React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import Image from "gatsby-image"

import Header from "./header"

const Layout = ({title, slogan, headerImage, fullwidth, children, paddingTop }) => {
  const data = useStaticQuery(
    graphql`
      query Header {
        headerImage: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-header-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 100, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgGeschichteFrueher: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-geschichte-frueher.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgGeschichteUmbau: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-geschichte-umbau.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgAlltag: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "alltagsgestaltung/bannau-alltagsgestaltung-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgAlltagBall: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "alltagsgestaltung/bannau-alltagsgestaltung-4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgGedTraining: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "angebot/gedaechtnistraining/bannau-gedaechtnistraining-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        imgGedTraining2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "angebot/gedaechtnistraining/bannau-gedaechtnistraining-4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        shiatsu1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        shiatsu2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-shiatsu-4.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        headerImageBig: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-header-1.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        lacremeLogo: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "lacreme-logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        bannauLehrbetrieb: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-lehrbetrieb.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        bannauLogo: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
      }
    `
  )
  
  const footer = (
    <footer style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          <div style={{margin: '0.5em 2em', flexGrow: '1'}}>
            <h5>Altersheim Bannau</h5>
            © {new Date().getFullYear()}, Altersheim Bannau<br/><br/>
            <a href="mailto:info@bannau.ch" style={{textDecoration: 'none'}}><span style={{marginRight: '0.5em'}} role="img" aria-label="E-Mail">✉️</span> info@bannau.ch</a><br/>
            <a href="tel:+41716221411" style={{textDecoration: 'none'}}><span style={{marginRight: '0.5em'}} role="img" aria-label="Telefon">📞</span> +41 71 622 14 11</a><br/><br/>
            Altersheim Bannau<br/>
            Gaswerkstrasse 6<br/>
            8570 Weinfelden<br/><br/>
          </div>
          <div style={{margin: '0.5em 2em', flexGrow: '1'}}>
            <h5>Links</h5>
            <Link href="/datenschutzerklaerung/" style={{textDecoration: 'none'}}>Datenschutzerklärung</Link><br/>
            <Link href="/kontakt/" style={{textDecoration: 'none'}}>Impressum</Link><br/>
            <a href="https://www.evang-weinfelden.ch/bannau" style={{textDecoration: 'none'}}>Evangelische Kirche Weinfelden</a>

            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', marginTop: '1em'}}>
              <Link href="/jobs/" style={{textDecoration: 'none'}}>
                <Image
                  fluid={data.bannauLehrbetrieb.childImageSharp.fluid}
                  style={{width: '50px', marginRight: '1em'}}
                />
              </Link>
              <Link href="/jobs/" style={{textDecoration: 'none'}}>
                Lehrbetrieb
              </Link>
            </div>
            <br/>
          </div>
                    
          <div style={{margin: '0.5em 2em', flexGrow: '0.5'}}>
            <h5>Webseite & Programmierung</h5>
            <a href="https://lacreme.digital"><Image fluid={data.lacremeLogo.childImageSharp.fluid} style={{width: '150px'}} alt="Lacreme Digital"/></a><br/>
          </div>
        </footer>
  )

  if(fullwidth) {
    return (
      <>
        <Header fullwidth="true" title={title} data={data} />
        <Image
          fluid={data.headerImageBig.childImageSharp.fluid}
          className="start-big-header-image"
        />
        <div className="start-wrapper">
          <main>{children}</main>
        </div>
        <div className="global-wrapper">
          <hr style={{marginBottom: '2em'}}/>
          {footer}
        </div>
      </>
    )
  } else {
    return (
      <>
      <Header headerImage={headerImage} title={title} slogan={slogan} data={data}/>
      <div className="global-wrapper" style={paddingTop ? {paddingTop: paddingTop} : {}}>
        <main>{children}</main>
        <hr style={{marginBottom: '2em'}}/>
        {footer}
      </div>
      </>
    )
  }
}

export default Layout

